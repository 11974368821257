import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { FilesRepository }  from 'modules/administration/files/services/repository';

@inject(CustomHttpClient, FilesRepository)
export class UsersRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param filesRepository
     */
    constructor(httpClient, filesRepository) {
        this.httpClient      = httpClient;
        this.filesRepository = filesRepository;
    }

    /**
     * Returns all records
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/users/all');
    }

    /**
     * Returns active records
     *
     * @returns {*}
     */
    active() {
        return this.httpClient.get('administration/users/active');
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/users/search', criteria);
    }

    /**
     * Creates a record
     *
     * @param data
     *
     * @returns {*}
     */
    create(data) {
        return this.httpClient.post('administration/users', data);
    }

    /**
     * Changes the password for a user
     *
     * @param data
     *
     * @returns {*}
     */
    changePassword(data) {
        return this.httpClient.post('administration/users/change-password', data);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get('administration/users/' + id);
    }

    /**
     * Returns authenticated user
     */
    authenticatedUser() {
        return this.httpClient.get('auth/authenticated-user');
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put('administration/users/' + id, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete('administration/users/' + id);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete('administration/users/destroy-selected', { ids: ids });
    }

    /**
     * Fetches user unread notifications from server
     *
     * @returns {*}
     */
    unreadNotifications() {
        return this.httpClient.get('administration/users/unread-notifications');
    }

    /**
     * Fetches user unread messages from server
     *
     * @returns {*}
     */
    unreadMessages() {
        return this.httpClient.get('administration/users/unread-messages');
    }

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost('administration/users/export', criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }
}
