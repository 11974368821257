import { inject }                 from 'aurelia-framework';
import { AppContainer }           from 'resources/services/app-container';
import { BaseFormViewModel }      from 'base-form-view-model';
import { TranslationsRepository } from 'modules/administration/translations/services/repository';
import { FormSchema }             from 'modules/administration/translations/create/form-schema';

@inject(AppContainer, TranslationsRepository, FormSchema)
export class CreateTranslation extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'administration.priorities.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    languages = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.translations.manage',
            'administration.translations.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        return this.fetchData(params).then(() => {
            this.initialModel = this.formSchema.model(this);
            this.model        = this.formSchema.model(this);
            this.schema       = this.formSchema.schema(this);
        });
    }

    /**
     * Submits view form
     */
    submit(redirectToList = true) {
        this.alert = null;

        this.repository
            .create(this.model)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    if (redirectToList) {
                        this.initialModel.assign(this.model);

                        this.redirectToRoute('administration.translations.index');
                    } else {
                        this.resetForm();
                    }
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch(error => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return this.repository.allLocales().then((response) => this.languages = response);
    }

}
