import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SettingsRepository {

    baseUrl = 'administration/settings';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Returns application settings
     *
     * @returns {*}
     */
    all() {
        return this.httpClient.get('administration/settings');
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('administration/settings/search', criteria);
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`administration/settings/${id}`);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put(`administration/settings/${id}`, data);
    }

    /**
     * Retrieves available options for a given setting
     *
     * @param id
     *
     * @returns {*}
     */
    availableOptions(id) {
        return this.httpClient.get(`administration/settings/${id}/available-options`);
    }
}
