import { inject }            from 'aurelia-framework';
import { PLATFORM }          from 'aurelia-pal';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/administration/users/form-schema';
import { UsersRepository }   from 'modules/administration/users/services/repository';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, UsersRepository, FormSchema)
export class EditUser extends BaseFormViewModel {

    headerTitle    = 'form.title.edit-record';
    newRecordRoute = 'administration.users.create';
    formId         = 'administration.users.edition-form';

    alert       = {};
    model       = {};
    schema      = {};
    permissions = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository     = repository;
        this.formSchema     = formSchema;
        this.newRecordRoute = appContainer.authenticatedUser.can(['administration.users.manage', 'administration.users.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/administration/users/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.manage',
            'administration.users.edit',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     * @returns {Promise.<*>|Promise}
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema             = this.formSchema.schema(this)
            this.globalButtonSchema = this.formSchema.globalButtonSchema(this);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert             = null;
        this.model.permissions = this.permissions;

        this.repository
            .update(this.model.id, this.model)
            .then(response => {
                if (response.status === true) {
                    this.initialModel.assign(this.model);

                    this.handleResponse(response, 'administration.users.index');
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            })
            .catch(console.error.bind(console));
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

}
