import { DialogController }             from 'aurelia-dialog';
import { bindable, inject }             from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { UserEntityTeamLotFormSchema }  from 'modules/administration/users/user-entity-team-lot/form-schema';
import { UserEntityTeamLotsRepository } from 'modules/administration/users/user-entity-team-lot/services/repository';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';

@inject(AppContainer, DialogController, UserEntityTeamLotsRepository, UserEntityTeamLotFormSchema, Downloader)
export class ViewUserEntityTeamLotModal extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';
    formId      = 'administration.users.entity-team-lot.view-form';
    readonly    = true;

    @bindable alert = {};
    model           = {};
    schema          = {};

    @bindable settings = {
        title:   'form.title.view-record',
        size:    'modal-xl',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     * @param downloader
     */
    constructor(appContainer, dialogController, repository, formSchema, downloader) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
        this.downloader       = downloader;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/administration/users/user-entity-team-lot/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.entity-team-lot.manage',
            'administration.users.entity-team-lot.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);
        this.schema       = this.formSchema.schema(this, true);
        this.globalSchema = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
