import { DialogController, DialogService } from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { BaseFormViewModel }               from 'base-form-view-model';
import { FormSchema }                      from 'modules/administration/settings/edit/form-schema';
import { SettingsFilesRepository }         from 'modules/administration/settings/services/files-repository';
import { SettingsRepository }              from 'modules/administration/settings/services/repository';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, SettingsRepository, SettingsFilesRepository, FormSchema, DialogController, DialogService)
export class EditSettings extends BaseFormViewModel {

    @bindable settings = {
        id:      'settings-modal',
        title:   'form.title.edit-record',
        buttons: true,
        size:    'modal-md',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param formSchema
     * @param dialogController
     * @param dialogService
     */
    constructor(appContainer, repository, filesRepository, formSchema, dialogController, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.filesRepository  = filesRepository;
        this.dialogController = dialogController;
        this.dialogService    = dialogService;
        this.formSchema       = formSchema;
        this.self             = this;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.settings.manage',
            'administration.settings.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.setting = params;

        this.schema       = this.formSchema.schema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(this.setting.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        return this.repository.update(this.setting.id, this.model)
            .then((response) => {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.initialModel.assign(this.model);

                    this.dialogController.ok();
                }

                this.appContainer.authenticatedUser.fetchAuthenticatedUser();
            })
    }
}
