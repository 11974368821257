import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class UserEntityTeamLotsRepository extends BaseRepository {
    baseUrl = 'administration/users/entity-team-lots';

    /**
     * Performs a search given some criteria for the user entity team lots teams
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchActiveTeams(criteria) {
        return this.httpClient.post(`${this.baseUrl}/search-active-teams`, criteria);
    }

    /**
     * Gets the next identifier suggestion
     *
     * @param entityId
     * @return {*}
     */
    nextIdentifierSuggestion(entityId) {
        return this.httpClient.get(`${this.baseUrl}/${entityId}/next-identifier-suggestion`);
    }

    /**
     * Returns all user entity team lots by lotInterventionId
     *
     * @param lotInterventionId
     * @return {*}
     */
    activeByLotIntervention(lotInterventionId) {
        return this.httpClient.get(`${this.baseUrl}/active/lot-intervention/${lotInterventionId}`);
    }
}
