import { Setting } from 'modules/administration/models/settings';

export class FormSchema {

    /**
     * Returns a new instance of the model
     *
     * @returns {Setting}
     */
    model() {
        let model = new Setting();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {
        this.value = {
            type:                   viewModel.setting.frontend_type,
            label:                  'form.field.value',
            size:                   12,
            key:                    'value',
            remoteSource:           viewModel.repository.availableOptions.bind(viewModel.repository),
            url:                    viewModel.filesRepository.uploadUri(),
            mockFile:               viewModel.setting.value,
            allowedMimeTypes:       viewModel.setting.mime_types,
            remoteSourceParameters: () => viewModel.setting.id,
        };

        return [
            [this.value],
        ];
    }
}
