import { bindable, inject }       from 'aurelia-framework';
import { BaseListViewModel }      from 'base-list-view-model';
import { TranslationsRepository } from 'modules/administration/translations/services/repository';
import { AppContainer }           from 'resources/services/app-container';

@inject(AppContainer, TranslationsRepository)
export class ListTranslations extends BaseListViewModel {

    listingId = 'administration-translations-listing';

    @bindable headerTitle    = 'listing.administration.translations';
    @bindable newRecordRoute = 'administration.translations.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.translations.manage',
            'administration.translations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.administration.translation',
            repository:      this.repository,
            edit:            'administration.translations.edit',
            destroy:         'administration.translations.destroy',
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'id',
                    name:  'translations.id',
                    title: '#',
                },
                {
                    data:  'group',
                    name:  'translations.group',
                    title: 'form.field.group',
                },
                {
                    data:  'key',
                    name:  'translations.key',
                    title: 'form.field.key',
                },
                {
                    data:  'text',
                    name:  'translation_lines.text',
                    title: 'form.field.text',
                },
            ],
        };
    }
}
