import { DialogService }      from 'aurelia-dialog';
import { bindable, inject }   from 'aurelia-framework';
import { PLATFORM }           from 'aurelia-pal';
import { BaseListViewModel }  from 'base-list-view-model';
import { EditSettings }       from 'modules/administration/settings/edit';
import { FilterFormSchema }   from 'modules/administration/settings/filter-form-schema';
import { SettingsRepository } from 'modules/administration/settings/services/repository';
import { AppContainer }       from 'resources/services/app-container';

@inject(AppContainer, SettingsRepository, DialogService, FilterFormSchema)
export class ListSettings extends BaseListViewModel {

    listingId = 'administration-settings-listing';

    @bindable headerTitle    = 'listing.administration.settings';
    @bindable newRecordRoute;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, dialogService, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogService    = dialogService;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.settings.manage',
            'administration.settings.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            edit:            (row) => this.viewSetting(row),
            destroy:         null,
            actions:         [],
            buttons:         [],
            selectable:      false,
            destroySelected: false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'module_name',
                    name:  'module_translations.name',
                    title: 'form.field.module',
                },
                {
                    data:  'name',
                    name:  'setting_translations.name',
                    title: 'form.field.setting-name',
                },
                {
                    data:      'value',
                    name:      'settings.value',
                    title:     'form.field.setting-value',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/administration/settings/custom-listing-cells/value/index'),
                },
            ],
        };
    }

    /**
     * Opens the application setting modal
     *
     * @param {*} row
     */
    viewSetting(row) {
        this.dialogService
            .open({
                viewModel: EditSettings,
                model:     row,
            })
            .whenClosed((response) => this.datatable.instance.reload());
    }
}
