import { DialogController }             from 'aurelia-dialog';
import { bindable, inject }             from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { UserEntityTeamLotFormSchema }  from 'modules/administration/users/user-entity-team-lot/form-schema';
import { UserEntityTeamLotsRepository } from 'modules/administration/users/user-entity-team-lot/services/repository';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, DialogController, UserEntityTeamLotsRepository, UserEntityTeamLotFormSchema)
export class CreateUserEntityTeamLotModal extends BaseFormViewModel {

    formId = 'administration.users.entity-team-lot.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    userId;

    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-xl',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/administration/users/user-entity-team-lot/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.users.entity-team-lot.manage',
            'administration.users.entity-team-lot.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.userId = params.user.id;

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create({ ...this.model, user_id: this.userId })
            .then((response) => {
                this.handleResponse(response);

                if (response.status === true) {
                    this.initialModel.assign(this.model);
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}
