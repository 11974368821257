import { computedFrom, inject }       from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { SettingsFilesRepository }    from 'modules/administration/settings/services/files-repository';
import { AppContainer }               from 'resources/services/app-container';
import { Downloader }                 from 'resources/services/downloader';
import { SanitizeHtmlValueConverter } from 'resources/value-converters/text-converters';

@inject(AppContainer, I18N, SanitizeHtmlValueConverter, SettingsFilesRepository, Downloader)
export class SettingsValueCustomListingCell {
    /**
     * Constructor
     *
     * @param appContainer
     * @param i18n
     * @param sanitizeHtmlValueConverter
     * @param filesRepository
     * @param downloader
     */
    constructor(appContainer, i18n, sanitizeHtmlValueConverter, filesRepository, downloader) {
        this.appContainer    = appContainer;
        this.i18n            = i18n;
        this.sanitizeHtml    = sanitizeHtmlValueConverter;
        this.filesRepository = filesRepository;
        this.downloader      = downloader;
    }

    @computedFrom('setting.value')
    get settingValue() {
        if (this.setting.backend_type === 'file') {
            this.download = true;
            return this.setting.value;
        }

        if (!this.setting.has_options) {
            return this.setting.value;
        }

        if (this.setting.backend_type !== 'array') {
            return this.setting.value.name;
        }

        return this.setting.value.map(value => value.name).join('</br>');
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column  = model.column;
        this.setting = model.row;
    }

    /**
     * Downloads the file
     *
     * @param file
     */
    downloadFile(file) {

        if (file && file.id) {
            return this.filesRepository.download(file.id)
                .then(result => {
                    if (result) {
                        // remove the file extension from the file name
                        const fileName = file.display_name.split('.').slice(0, -1).join('.');
                        this.downloader.download(result, fileName, file.extension);
                    }
                });
        }

        this.appContainer.notifier['dangerNotice'](this.i18n.tr('form.message.please-upload-a-file-first'));
    }
}
